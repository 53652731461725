<template>
  <v-card height="100%">
    <v-card-title class="pa-0" style="height: 50px">
      <v-toolbar :color="systemDetails.themecolor" dark  height="50px">
        <v-toolbar-title :style="`color: ${systemDetails.textcolor}`">{{ $t('workflow') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialogHandler">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0" :style="`color:black!important; height: calc(100% - ${modelObj.recipients.status ? '50px' : '103px'}); overflow-y: auto`">
      <div class="pa-2 ma-2 mb-0">
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('subject') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.subject || '' }}</span>
            <v-icon size="20" class="pl-1" @click="navigateToOD(modelObj.document)" v-if="modelObj.recipients.status">mdi-open-in-new</v-icon>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('description') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.description || '' }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('createdBy') }}:</span>
            <span class="text-left pb-0 pt-0">{{ getUsername(modelObj.createdby) }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('createdOn') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.created_at ? $formatter.formatDate(modelObj.created_at, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)  : '' }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 pr-2 subtitle-1 font-weight-bold">{{ $t('comments') }}:</span>
            <span class="text-left">{{ modelObj.recipients.comments }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 subtitle-1 font-weight-bold pr-2">{{ $t('reviewedAt') }}:</span>
            <span class="text-left pb-0 pt-0">{{ modelObj.recipients.reviewedat ? $formatter.formatDate(modelObj.recipients.reviewedat, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`) : modelObj.recipients.reviewedat }}</span>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" v-if="modelObj.recipients.status">
          <span class="text-right pb-0 pt-0 subtitle-1 font-weight-bold pr-2">{{ $t('status') }}:</span>
            <v-chip class="ma-2" outlined label :color="modelObj.recipients.statusColor">
              {{ modelObj.recipients.statusName }}
            </v-chip>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'">
            <span class="text-right pb-0 pt-0 subtitle-2 font-weight-bold pr-2">{{ $t('commentsHistory') }}:</span>
            <v-icon color="teal" size="25" @click="openRecipientCommentDialog(modelObj.recipients.userid)">{{ commentsLoader ? 'mdi-spin mdi-loading' : 'mdi-chat' }}</v-icon>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined v-model="modelObj.recipients.recipientcomments" :label="$t('recipientComments')"></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <template v-if="!modelObj.recipients.status">
      <v-divider></v-divider>
      <v-card-actions style="height: 52px">
        <v-flex class="text-center">
          <template v-if="!isDocumentRead">
            <v-btn small color="primary" @click="navigateToOD(modelObj.document); isDocumentRead = true">{{ $t('readDocument') }}</v-btn>
          </template>
          <template v-else>
            <template v-if="modelObj.action === ACTION_ACKNOWLEDGE">
              <v-btn small color="primary" class="mr-3" outlined @click="documentActionHandler(ACTION_ACKNOWLEDGED)" :loading="loaderObj[ACTION_ACKNOWLEDGED]">{{ $t('acknowledged') }}</v-btn>
              <v-btn small color="error" outlined @click="documentActionHandler(ACTION_REJECTED)" :loading="loaderObj[ACTION_REJECTED]">{{ $t('rejected') }}</v-btn>
            </template>
            <template v-if="modelObj.action === ACTION_REVIEW_AND_MAKE_CHANGES">
              <v-btn small color="primary" class="mr-3" outlined @click="documentActionHandler(ACTION_REVIEWED)" :loading="loaderObj[ACTION_REVIEWED]">{{ $t('reviewed') }}</v-btn>
              <v-btn small color="error" outlined @click="documentActionHandler(ACTION_REJECTED)" :loading="loaderObj[ACTION_REJECTED]">{{ $t('rejected') }}</v-btn>
            </template>
            <template v-if="modelObj.action === ACTION_APPROVE_OR_DISAPPROVE">
              <v-btn small color="primary" class="mr-3" outlined @click="documentActionHandler(ACTION_APPROVED)" :loading="loaderObj[ACTION_APPROVED]">{{ $t('approve') }}</v-btn>
              <v-btn small color="error" outlined @click="documentActionHandler(ACTION_DISAPPROVED)" :loading="loaderObj[ACTION_DISAPPROVED]">{{ $t('disapprove') }}</v-btn>
            </template>
            <template v-if="modelObj.action === ACTION_INFORMATION">
              <v-btn small color="primary" class="mr-3" outlined @click="documentActionHandler(ACTION_GOTIT)" :loading="loaderObj[ACTION_GOTIT]">{{ $t('gotIt') }}</v-btn>
            </template>
          </template>
        </v-flex>
      </v-card-actions>
    </template>
    <v-dialog v-model="recipientCommentsDialog" width="1000">
      <v-card flat class="recipient">
        <v-card-title class="pa-0">
          <v-toolbar :color="$vuetify.theme.dark ? 'black' : systemDetails.themecolor" :dark="$vuetify.theme.dark" height="55" :style="`color: ${$vuetify.theme.dark ? 'white' : systemDetails.textcolor}`">
            <v-toolbar-title class="body">{{ $t('comments') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="recipientCommentsDialog = false" :color="$vuetify.theme.dark ? 'white' : systemDetails.textcolor">mdi-close-circle-outline</v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" class="flex-grow-1 flex-shrink-0 pa-0">
                <v-responsive class="overflow-y-hidden fill-height">
                  <v-card flat class="d-flex flex-column fill-height">
                    <v-card-text class="overflow-y-auto pa-0 pr-2 chat-body">
                      <template v-for="(comment, i) in listOfComments">
                        <div :key="i" :class="{ 'd-flex flex-row-reverse': !comment.iscreator }">
                          <v-avatar size="25px" :color="comment.userobject[0].profile ? '' : 'indigo'" class="mx-2" v-if="comment.iscreator">
                            <img v-if="comment.userobject[0].profile" alt="Avatar" :src="$formatter.getAppImageURL() +'/' + comment.userobject[0].profile">
                            <span v-else class="caption white--text">
                              {{  comment.userobject[0].name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() }}
                            </span>
                          </v-avatar>
                          <v-chip :color="!comment.iscreator ? 'primary' : ''" dark style="height:auto;white-space: normal;" class="pa-1 pl-2 mb-1 caption" :ripple="false">
                            {{ comment.message }}
                          </v-chip>
                        </div>
                        <p class="ml-2 caption font-weight-medium" :key="`date_${i}`" :class="{ 'd-flex flex-row-reverse': !comment.iscreator }">
                          <!-- {{ $formatter.fromUtcToLocal(comment.sentdate, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') }} -->
                          {{ $formatter.formatDate(comment.sentdate, '', `${userDetails.dateformat} HH:mm`) }}
                        </p>
                      </template>
                    </v-card-text>
                    <v-card-text class="flex-shrink-1">
                      <v-textarea v-model="replyObj.message" :label="$t('typeHere')" type="text" rows="3" no-details outlined :append-outer-icon="commentsLoader ? 'mdi-spin mdi-loading' : 'mdi-send'"
                        @click:append-outer="saveComment()" hide-details/>
                    </v-card-text>
                  </v-card>
                </v-responsive>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['workflowObj'],
  data () {
    return {
      modelObj: {
        recipients: {}
      },
      loaderObj: {},
      isDocumentRead: false,
      listOfComments: [],
      recipientCommentsDialog: false,
      commentsLoader: false,
      replyObj: {
        message: '',
        iscreator: false
      }
    }
  },
  mounted () {
    const modelObj = this.$formatter.cloneVariable(this.workflowObj)
    if (modelObj.recipients && modelObj.recipients.length > 0) {
      const usersFound = modelObj.recipients.find(x => x.userid === this.userDetails._id)
      if (usersFound) {
        modelObj.recipients = usersFound
        if (modelObj.recipients.status) {
          const statusInfo = this.getStatusText(modelObj.recipients.status)
          if (statusInfo) {
            modelObj.recipients.statusName = statusInfo.name
            modelObj.recipients.statusColor = statusInfo.color
          }
        }
      }
    }
    this.modelObj = modelObj
  },
  computed: {
    ...mapGetters(['getUsers', 'userDetails'])
  },
  methods: {
    closeDialogHandler () {
      this.$eventBus.$emit('closeWorkflowDialog')
    },
    getActionName (action) {
      const actionFound = this.listOfRouteActions.find(x => x.id === action)
      if (actionFound) return actionFound.text
      else return ''
    },
    getUsername (id) {
      if (id) {
        const userFound = this.getUsers.find(x => x._id === id)
        if (userFound) return userFound.name
        else return ''
      } else return ''
    },
    documentActionHandler (action) {
      const model = this.$formatter.cloneVariable(this.modelObj.recipients)
      model.status = action
      model.reviewedat = model.reviewedat ? this.$formatter.formatDate(model.reviewedat, 'DD.MM.YYYYTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss') : null
      this.$set(this.loaderObj, action, true)
      this.$api.execute('post', `documentworkflows/submit_user_review/${this.modelObj._id}/${this.modelObj.routedocorfolderid}`, model)
        .then(() => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'statusSuccess' })
          this.loader = false
          this.$eventBus.$emit('closeWorkflowDialog', true)
        })
        .finally(() => {
          this.$set(this.loaderObj, action, false)
        })
    },
    getStatusText (status) {
      const resultFound = this.listOfWorkflowStatus.find(x => x.id === status)
      if (resultFound) return resultFound
      else return ''
    },
    openRecipientCommentDialog (userid) {
      this.commentsLoader = true
      this.$api.execute('post', 'documentworkflowconversation/get_workflow_conversation', { workflowid: this.workflowObj._id, userid }).then(response => {
        this.currentReceipientId = userid
        this.listOfComments = response.data
        this.recipientCommentsDialog = true
      }).finally(() => {
        this.commentsLoader = false
      })
    },
    saveComment () {
      const model = this.$formatter.cloneVariable(this.replyObj)
      model.workflowid = this.workflowObj._id
      model.userid = this.currentReceipientId
      model.iscreator = false
      this.commentsLoader = true
      this.$api.execute('post', 'documentworkflowconversation/add_comment', model).then(response => {
        model.sentdate = this.$formatter.getCurrentDateTimeUTC()
        this.listOfComments.push(model)
        this.replyObj = {
          message: '',
          iscreator: false
        }
      }).finally(() => {
        this.commentsLoader = false
      })
    }
  }
}
</script>
<style>
.recipient .theme--light .v-sheet{
  background: none;
}
</style>
